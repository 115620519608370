import { useState, useCallback, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { MicroService, millisecondsToTime, NotificationType } from "@growerz/shared";

import AdminApiService from "../../services/AdminApiService";
import { useNotification } from "../../contexts/NotificationContext";

const ServicesPanel = () => {
    const { addErrorNotification, addNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState<MicroService[]>();
    const [totalServices, setTotalServices] = useState<number>(0);

    const refreshData = useCallback(() => {
        setLoading(true);
        AdminApiService.get('/services/list').then((response) => {
            if (!response.success) throw new Error(response.message!);
            if (response.data.length !== totalServices) setTotalServices(response.data.total);
            setServices(response.data.data);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get service data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addErrorNotification, totalServices]);

    const runService = useCallback((event: any) => {
        setLoading(true);
        AdminApiService.post(`/services/run/${event.currentTarget.value}`).then((response) => {
            if (!response.success) throw new Error(response.message!);
            addNotification("Action Success", `Service ${event.currentTarget.value} started!`, NotificationType.Success);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get service data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addNotification, addErrorNotification]);

    const startService = useCallback((event: any) => {
        setLoading(true);
        AdminApiService.post(`/services/start/${event.currentTarget.value}`).then((response) => {
            if (!response.success) throw new Error(response.message!);
            addNotification("Action Success", `Service ${event.currentTarget.value} started!`, NotificationType.Success);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get service data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addNotification, addErrorNotification]);

    const stopService = useCallback((event: any) => {
        setLoading(true);
        AdminApiService.post(`/services/stop/${event.currentTarget.value}`).then((response) => {
            if (!response.success) throw new Error(response.message!);
            addNotification("Action Success", `Service ${event.currentTarget.value} stopped!`, NotificationType.Success);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get service data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addNotification, addErrorNotification]);

    const startAllServices = useCallback(() => {
        setLoading(true);
        AdminApiService.post(`/services/startall`).then((response) => {
            if (!response.success) throw new Error(response.message!);
            addNotification("Action Success", `All services have been started!`, NotificationType.Success);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get service data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addNotification, addErrorNotification]);

    const stopAllServices = useCallback(() => {
        setLoading(true);
        AdminApiService.post(`/services/stopall`).then((response) => {
            if (!response.success) throw new Error(response.message!);
            addNotification("Action Success", `All services have been stopped!`, NotificationType.Success);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get service data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addNotification, addErrorNotification]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const Services = () => {
        if (loading) return (<tr>
            <td colSpan={7} className="text-center">
                Loading...
            </td>
        </tr>)

        if (!services || services.length === 0) return (<tr>
            <td colSpan={7} className="text-center">
                No available data
            </td>
        </tr>)

        return (<>{services.map((service: MicroService) =>
            <tr key={service.shortName}>
                <td>{service.name}</td>
                <td>{millisecondsToTime(service.intervalMs)}</td>
                <td>{service.status}</td>
                <td>
                    <Row sm={5}>
                        <Col>
                            <Button variant={"thc"} className="text-white" onClick={runService} disabled={loading} value={service.shortName}>Run</Button>
                        </Col>
                        <Col>
                            <Button variant={"success"} className="text-white" onClick={startService} disabled={loading} value={service.shortName}>Start</Button>
                        </Col>
                        <Col>
                            <Button variant={"danger"} className="text-white" onClick={stopService} disabled={loading} value={service.shortName}>Stop</Button>
                        </Col>
                    </Row>
                </td>
            </tr>)
        }</>)
    }

    return (<>
        <Row className="mb-3 justify-content-between">
            <Col sm={6}>
                <h3>Service Management</h3>
            </Col>
            <Col sm={6} className="text-end">
                <Row className="justify-content-end text-end g-0">
                    <Col sm={3}>
                        <Button variant={"thc"} className="text-white" onClick={refreshData} disabled={loading}>Refresh</Button>
                    </Col>
                    <Col sm={3}>
                        <Button variant={"success"} className="text-white" onClick={startAllServices} disabled={loading}>Start All</Button>
                    </Col>
                    <Col sm={3}>
                        <Button variant={"danger"} className="text-white" onClick={stopAllServices} disabled={loading}>Stop All</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Table className="fs-7 w-75">
            <thead>
                <tr>
                    <th>Service</th>
                    <th>Interval</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <Services />
            </tbody>
        </Table>
    </>)
}

export default ServicesPanel;