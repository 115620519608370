"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceStatus = exports.PaymentMethod = exports.ValidationStrictness = exports.GrowhouseSize = exports.GrowhouseHubSize = exports.LogCategory = exports.LogLevel = exports.NotificationType = exports.PlantAction = exports.GrowhouseAction = exports.Rarity = exports.PlantState = void 0;
var PlantState;
(function (PlantState) {
    PlantState[PlantState["DEAD"] = -2] = "DEAD";
    PlantState[PlantState["WITHERED"] = -1] = "WITHERED";
    PlantState[PlantState["SEED"] = 0] = "SEED";
    PlantState[PlantState["SAPLING"] = 1] = "SAPLING";
    PlantState[PlantState["PLANT"] = 2] = "PLANT";
    PlantState[PlantState["HARVESTABLE"] = 3] = "HARVESTABLE";
    PlantState[PlantState["HARVESTED"] = 4] = "HARVESTED";
})(PlantState || (exports.PlantState = PlantState = {}));
var Rarity;
(function (Rarity) {
    Rarity["COMMON"] = "Common";
    Rarity["UNCOMMON"] = "Uncommon";
    Rarity["RARE"] = "Rare";
    Rarity["EPIC"] = "Epic";
    Rarity["MYTHIC"] = "Mythic";
})(Rarity || (exports.Rarity = Rarity = {}));
var GrowhouseAction;
(function (GrowhouseAction) {
    GrowhouseAction["PLANT"] = "Plant";
    GrowhouseAction["WATER"] = "Water";
    GrowhouseAction["HARVEST"] = "Harvest";
    GrowhouseAction["ADD_GROWER"] = "AddGrower";
    GrowhouseAction["REMOVE_GROWER"] = "RemoveGrower";
    GrowhouseAction["UPGRADE"] = "Upgrade";
    GrowhouseAction["REQUIREMENTS"] = "Requirements";
})(GrowhouseAction || (exports.GrowhouseAction = GrowhouseAction = {}));
var PlantAction;
(function (PlantAction) {
    PlantAction["PLANT"] = "plant";
    PlantAction["WATER"] = "water";
    PlantAction["HARVEST"] = "harvest";
    PlantAction["TRANSFER"] = "transfer";
    PlantAction["REVIVE"] = "revive";
    PlantAction["PRUNE"] = "prune";
})(PlantAction || (exports.PlantAction = PlantAction = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["Error"] = "danger";
    NotificationType["Message"] = "secondary";
    NotificationType["Success"] = "success";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
var LogLevel;
(function (LogLevel) {
    LogLevel["SERVER"] = "Server";
    LogLevel["INFO"] = "Info";
    LogLevel["WARNING"] = "Warning";
    LogLevel["ERROR"] = "Error";
    LogLevel["CRITICAL"] = "Critical";
})(LogLevel || (exports.LogLevel = LogLevel = {}));
var LogCategory;
(function (LogCategory) {
    LogCategory["SERVER_ACTION"] = "Server Action";
    LogCategory["SERVICE_ACTION"] = "Service Action";
    LogCategory["USER_ACTION"] = "User Action";
    LogCategory["ADMIN_ACTION"] = "Admin Action";
    LogCategory["ERROR"] = "Error";
})(LogCategory || (exports.LogCategory = LogCategory = {}));
var GrowhouseHubSize;
(function (GrowhouseHubSize) {
    GrowhouseHubSize["SMALL"] = "small";
    GrowhouseHubSize["MEDIUM"] = "medium";
    GrowhouseHubSize["LARGE"] = "large";
})(GrowhouseHubSize || (exports.GrowhouseHubSize = GrowhouseHubSize = {}));
var GrowhouseSize;
(function (GrowhouseSize) {
    GrowhouseSize[GrowhouseSize["SMALL"] = 0] = "SMALL";
    GrowhouseSize[GrowhouseSize["MEDIUM"] = 1] = "MEDIUM";
    GrowhouseSize[GrowhouseSize["LARGE"] = 2] = "LARGE";
})(GrowhouseSize || (exports.GrowhouseSize = GrowhouseSize = {}));
var ValidationStrictness;
(function (ValidationStrictness) {
    ValidationStrictness["PENDING"] = "pending";
    ValidationStrictness["CONFIRMED"] = "confirmed";
    ValidationStrictness["FINALIZED"] = "finalized";
})(ValidationStrictness || (exports.ValidationStrictness = ValidationStrictness = {}));
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["THC"] = "thc";
    PaymentMethod["SOL"] = "sol";
})(PaymentMethod || (exports.PaymentMethod = PaymentMethod = {}));
var ServiceStatus;
(function (ServiceStatus) {
    ServiceStatus["STOPPED"] = "STOPPED";
    ServiceStatus["RUNNING"] = "RUNNING";
    ServiceStatus["IDLE"] = "IDLE";
    ServiceStatus["ERROR"] = "ERROR";
})(ServiceStatus || (exports.ServiceStatus = ServiceStatus = {}));
