import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { AccountSubscriptionConfig, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { GrowerData } from '../../../shared';
import { getNftsByOwner, getTokenInfo } from '../helpers/SolanaHelper';

// Define the context
interface SolanaWalletContextProps {
    balance: number;
    tokenBalance: number;
    updateBalance: () => void;
    growerz: GrowerData[];
    updateGrowerz: () => void;
}

const SolanaWalletContext = createContext<SolanaWalletContextProps>({
    balance: 0,
    tokenBalance: 0,
    updateBalance: () => { },
    growerz: [],
    updateGrowerz: () => { }
});

// Define the custom hook
export const useSolanaWallet = () => {
    const context = useContext(SolanaWalletContext);
    if (!context) {
        throw new Error('useWalletBalance must be used within a SolanaWalletProvider');
    }
    return context;
};

// Define the provider component
export const SolanaWalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { connection } = useConnection();
    const { connected, disconnecting, publicKey } = useWallet();
    const [tokenBalance, setTokenBalance] = useState<number>(0);
    const [balance, setBalance] = useState<number>(0);
    const [growerz, setGrowerz] = useState<GrowerData[]>([]);

    const getWalletTokenBalance = useCallback(async (wallet: PublicKey) => {
        let tokenInfo = await getTokenInfo(connection, wallet);
        return tokenInfo.account.data.parsed.info.tokenAmount.uiAmount ?? 0;
    }, [connection]);

    const getWalletBalance = useCallback(async (wallet: PublicKey) => {
        const balance = await connection.getBalance(wallet);
        return (balance / LAMPORTS_PER_SOL);
    }, [connection]);

    const getWalletBalances = useCallback(async (wallet: PublicKey) => {
        return {
            balance: await getWalletBalance(wallet),
            tokenBalance: await getWalletTokenBalance(wallet)
        }
    }, [getWalletBalance, getWalletTokenBalance]);

    const updateBalance = useCallback(() => {
        if (!publicKey) return;

        getWalletBalances(publicKey)
            .then((results) => {
                if (!results) throw new Error();
                setTokenBalance(results.tokenBalance);
                setBalance(results.balance);
            }).catch(() => {
                setTokenBalance(0);
                setBalance(0);
            });
    }, [getWalletBalances, publicKey]);

    const updateGrowerz = useCallback(() => {
        if (!publicKey || !connected) return;

        getNftsByOwner(connection, publicKey)
            .then((results) => {
                if (!results) throw new Error();
                setGrowerz(results);
            }).catch(() => {
                setGrowerz([]);
            });
    }, [publicKey, connected, connection]);

    const value = useMemo(() => ({
        balance,
        tokenBalance,
        growerz,
        updateBalance,
        updateGrowerz
    }), [updateBalance, updateGrowerz, balance, tokenBalance, growerz]);

    useEffect(() => {
        if (!publicKey || !connected) return;
        if (disconnecting) {
            setBalance(0);
            setTokenBalance(0);
            return;
        }

        updateBalance();
        updateGrowerz();

        const config: AccountSubscriptionConfig = {
            commitment: 'processed'
        }

        const subId = connection.onAccountChange(
            publicKey,
            () => updateBalance(),
            config
        )

        return () => {
            connection.removeAccountChangeListener(
                subId
            )
        }
    }, [publicKey, connected, disconnecting, connection, updateBalance, updateGrowerz]);

    return (
        <SolanaWalletContext.Provider value={value}>
            {children}
        </SolanaWalletContext.Provider>
    );
};
