import { Metaplex } from "@metaplex-foundation/js";
import { MEMO_PROGRAM_ID, createMemoInstruction } from "@solana/spl-memo";
import { createTransferInstruction, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, LAMPORTS_PER_SOL, ParsedAccountData, PublicKey, SystemProgram, TransactionInstruction, TransactionMessage, VersionedTransaction } from "@solana/web3.js";

import ApiService from "../services/ApiService";

const SPL_TOKEN = process.env.REACT_APP_SPL_TOKEN ?? TOKEN_PROGRAM_ID;
const UPDATE_AUTHORITY = process.env.REACT_APP_UPDATE_AUTHORITY ?? '';
const VAULT = process.env.REACT_APP_HUB_VAULT ?? '';

export const getNftsByOwner = async (connection: Connection, owner: PublicKey) => {
    const metaplex = new Metaplex(connection);

    const update_authority = new PublicKey(UPDATE_AUTHORITY);
    const nfts = await metaplex.nfts().findAllByOwner({ owner }).then((nfts) => {
        return nfts.filter((nft) => nft.updateAuthorityAddress.toBase58() === update_authority.toBase58())
    });

    const mints = nfts.map((nft: any) => nft.mintAddress);
    const response = await ApiService.post('/growerz', { growerz: mints });
    if (!response) throw new Error("No NFTs available");

    /*let growerz = response.data.map(async (grower: GrowerData) => {
        let uri = nfts.filter((nft: any) => nft.mintAddress.toBase58() === grower.address)[0].uri;
        const metadata = await fetch(uri).then((data) => {
            return data.json();
        });

        grower.image = metadata.properties.files[0].uri;
        return grower;
    });*/

    return response.data;
}

export const getTokenInfo = async (connection: Connection, wallet: PublicKey) => {
    const info = await connection.getParsedTokenAccountsByOwner(wallet, { mint: new PublicKey(SPL_TOKEN) });
    return info.value[0];
}

export const createPlayerTransaction = async (connection: Connection, sender: PublicKey, amount: number, paymentMethod?: string) => {
    const latestBlockhash = await connection.getLatestBlockhash();

    let instruction: TransactionInstruction;

    if (!paymentMethod || paymentMethod === "thc") {
        const numberDecimals = await getNumberDecimals(connection, new PublicKey(SPL_TOKEN));
        const fromTokenInfo = await getTokenInfo(connection, sender);
        const fromTokenAddress = fromTokenInfo.pubkey;
        const toTokenInfo = await getTokenInfo(connection, new PublicKey(VAULT));
        const toTokenAddress = toTokenInfo.pubkey;

        instruction = createTransferInstruction(
            fromTokenAddress,
            toTokenAddress,
            sender,
            amount * Math.pow(10, numberDecimals),
            [],
            TOKEN_PROGRAM_ID
        );
    } else {
        instruction = SystemProgram.transfer({
            fromPubkey: sender,
            toPubkey: new PublicKey(VAULT),
            lamports: LAMPORTS_PER_SOL * amount,
        })
    }

    const messageLegacy = new TransactionMessage({
        payerKey: sender,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: [instruction],
    }).compileToLegacyMessage();

    return new VersionedTransaction(messageLegacy);
}

export const createMemoTransaction = async (sender: PublicKey, message: Uint8Array) => {
    const instruction = new TransactionInstruction({
        keys: [
            { pubkey: sender, isSigner: true, isWritable: true },
        ],
        programId: new PublicKey(MEMO_PROGRAM_ID),
        data: Buffer.from(message),
    });

    return instruction;
}

export const createValidationTransaction = async (connection: Connection, sender: PublicKey) => {
    const latestBlockhash = await connection.getLatestBlockhash();
    const instructions = [
        createMemoInstruction("THC Labz Authentication", [sender])
    ];

    const messageLegacy = new TransactionMessage({
        payerKey: sender,
        recentBlockhash: latestBlockhash.blockhash,
        instructions,
    }).compileToLegacyMessage();

    return new VersionedTransaction(messageLegacy);
}

const getNumberDecimals = async (connection: Connection, token: PublicKey): Promise<number> => {
    const info = await connection.getParsedAccountInfo(token);
    return (info.value?.data as ParsedAccountData).parsed.info.decimals as number;
}