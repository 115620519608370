import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { StrictMode } from 'react';
import SolanaProvider from './providers/SolanaProvider';
import { ApiProvider } from './contexts/ApiContext';
import { IdentityProvider } from './contexts/IdentityContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { SolanaWalletProvider } from './hooks/useSolanaWallet';
import { CartProvider } from './hooks/useCart';
import { GrowhouseProvider } from './hooks/useGrowhouse';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <StrictMode>
        <BrowserRouter>
            <ApiProvider>
                <NotificationProvider>
                    <SolanaProvider>
                        <IdentityProvider>
                            <SolanaWalletProvider>
                                <GrowhouseProvider>
                                    <CartProvider>
                                        <App />
                                    </CartProvider>
                                </GrowhouseProvider>
                            </SolanaWalletProvider>
                        </IdentityProvider>
                    </SolanaProvider>
                </NotificationProvider>
            </ApiProvider>
        </BrowserRouter>
    </StrictMode>
);
