import { GrowerData, NotificationType } from "@growerz/shared";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useApi } from "../../../contexts/ApiContext";
import { useNotification } from "../../../contexts/NotificationContext";
import useGrowhouse from "../../../hooks/useGrowhouse";

const MobileGrowerLicense = ({
    swappingMint,
    inList,
    grower,
    disabled,
    show,
    hide
}: {
    swappingMint?: string,
    inList: boolean,
    grower: GrowerData,
    disabled?: boolean
    show: (event: any) => void,
    hide: () => void
}) => {
    const { post } = useApi();
    const { growhouseId } = useParams();
    const { addNotification, addErrorNotification } = useNotification();
    const { refreshData } = useGrowhouse();

    async function handleSelect(event: any) {
        let mint = event.currentTarget.value;
        post(`/growhouse/${growhouseId}/addgrower`, {
            mint
        }).then((response) => {
            if (!response.success) {
                addErrorNotification("Action Failed", `Failed to add Grower, ${response.message}`);
                console.error("Failed to add Grower: " + response.message);
            } else {
                addNotification("Action Successful", "Grower added to Growhouse", NotificationType.Success);
                refreshData();
                hide();
            }
        }).catch((error: any) => {
            addErrorNotification("Action Failed", "Failed to add Grower, contact support!");
            console.log("Failed to add Grower: ", error.message);
        });
    }

    async function handleSwap(event: any) {
        let mint = event.currentTarget.value;
        post(`/growhouse/${growhouseId}/swapgrower`, {
            oldMint: swappingMint,
            newMint: mint
        }).then((response) => {
            if (!response.success) {
                addErrorNotification("Action Failed", `Failed to add Grower, ${response.message}`);
                console.error("Failed to add Grower: " + response.message);
            } else {
                addNotification("Action Successful", "Grower added to Growhouse", NotificationType.Success);
                refreshData();
                hide();
            }
        }).catch((error: any) => {
            addErrorNotification("Action Failed", "Failed to add Grower, contact support!");
            console.log("Failed to add Grower: ", error.message);
        });
    }

    const ActionButton = () => {
        if (inList) return <Row className="g-0">
            <Col>
                <Button variant="success" className='w-100 br-0 br-bs-7 fs-7' size={"sm"} onClick={show} value={grower.address}>
                    Swap Grower
                </Button>
            </Col>
        </Row>

        if (swappingMint) {
            return <Row className="g-0">
                <Col>
                    <Button variant="success" className='w-100 br-0 br-b-7 fs-7' size="lg" onClick={handleSwap} value={grower.address} disabled={disabled}>
                        Swap Grower
                    </Button>
                </Col>
            </Row>
        }

        return <Row className="g-0">
            <Col>
                <Button variant="success" className='w-100 br-0 br-b-7 fs-7' size="lg" onClick={handleSelect} value={grower.address}>
                    Select Grower
                </Button>
            </Col>
        </Row>
    }

    const GrowerImage = () => {
        return <img
            alt={`Grower #${grower.id}`}
            src={grower.image}
            className="w-100"
            key={grower.id} />
    }

    return <Card bg={"dark"} text={"white"} className={`growerz-license border-${grower.class.name.toLowerCase()}`}>
        <Card.Header className={`bg-${grower.class.name.toLowerCase()} text-white text-center px-0 fs-7`}>
            Grower&nbsp;#{grower.id}
        </Card.Header>
        <Card.Body className="license-bg text-center p-0">
            <GrowerImage />
        </Card.Body>
        <Card.Footer className="p-0">
            <ActionButton />
        </Card.Footer>
    </Card>
}

export default MobileGrowerLicense;