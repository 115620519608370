import { useState, useCallback, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { AccountData } from "@growerz/shared";

import AdminApiService from "../../services/AdminApiService";
import { useNotification } from "../../contexts/NotificationContext";
import PaginationComponent from "../common/Pagination";
import AddAdminModal from "./modals/AddAdmin";
import EditAdminModal from "./modals/EditAdmin";

const AdminsPanel = () => {
    const { addErrorNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState<AccountData[]>();
    const [totalAdmins, setTotalAdmins] = useState<number>(0);

    const [searchAddress, setSearchAddress] = useState();
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(25);

    const refreshData = useCallback(() => {
        setLoading(true);
        AdminApiService.post('/admins/all', {
            address: searchAddress,
            skip,
            count
        }).then((response) => {
            if (!response.success) throw new Error(response.message!);
            if (response.data.total !== totalAdmins) setTotalAdmins(response.data.total);
            setAdmins(response.data.data);
        }).catch((error: any) => {
            addErrorNotification("Failed to fetch data", error.message);
            console.log("Failed to get user data: ", error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [addErrorNotification, searchAddress, skip, count, totalAdmins]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const updateSearchAddress = (event: any) => {
        setSearchAddress(event.currentTarget.value);
        setSkip(0);
    }

    const updateCount = (event: any) => {
        setCount(event.currentTarget.value);
        setSkip(0);
    }

    const handlePageChange = (page: number) => {
        setSkip((page * count) - count);
    };

    const Users = () => {
        if (loading) return (<tr>
            <td colSpan={7} className="text-center">
                Loading...
            </td>
        </tr>)

        if (!admins || admins.length === 0) return (<tr>
            <td colSpan={7} className="text-center">
                No available data
            </td>
        </tr>)

        return (<>{admins.map((admin: AccountData) =>
            <tr key={admin.id}>
                <td>{admin.id}</td>
                <td>{admin.address}</td>
                <td>{admin.banned ? 'true' : 'false'}</td>
                <td>{admin.role?.admin ? 'true' : 'false'}</td>
                <td>{admin.role?.superadmin ? 'true' : 'false'}</td>
                <td>{admin.date_created?.toString()}</td>
                <td>
                    <EditAdminModal admin={admin} loading={loading} refreshData={refreshData} />
                </td>
            </tr>)
        }</>)
    }

    return (<>
        <Row className="mb-3 justify-content-between">
            <Col sm={6}>
                <h3>Admin Management</h3>
            </Col>
            <Col sm={6} className="text-end">
                <Row className="justify-content-end g-3">
                    <Col sm={2}>
                        <Button variant={"thc"} className="text-white" onClick={refreshData} disabled={loading}>Refresh</Button>
                    </Col>
                    <Col sm={2}>
                        <AddAdminModal loading={loading} refreshData={refreshData} />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="mb-3 justify-content-end">
            <Col sm={4}>
                <InputGroup size="sm">
                    <InputGroup.Text id="search-address">Address</InputGroup.Text>
                    <Form.Control
                        onChange={updateSearchAddress}
                        defaultValue={searchAddress}
                        placeholder="FkRn..."
                        aria-label="Address"
                        aria-describedby="search-address"
                        size="sm"
                    />
                </InputGroup>
            </Col>
        </Row>
        <Table className="fs-7">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Address</th>
                    <th>Banned</th>
                    <th>Admin</th>
                    <th>Super Admin</th>
                    <th>Date Registered</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <Users />
            </tbody>
        </Table>
        <Row className="justify-content-end">
            <Col>
                <PaginationComponent total={totalAdmins} count={count} updateData={handlePageChange} skip={skip} />
            </Col>
            <Col sm={6}>
                <Row>
                    <Col className="pt-2 text-end">
                        <i className="text-muted">
                            Total Records: {totalAdmins}
                        </i>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text id="search-count">Count</InputGroup.Text>
                            <Form.Select
                                value={count}
                                defaultValue={count}
                                onChange={updateCount}
                                aria-label="Count"
                                aria-describedby="search-count">
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}

export default AdminsPanel;