import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';

import {
    GrowerData,
    GrowhouseData,
    GrowhouseStats,
    HarvestCaps,
    PlantData,
    PlantState,
    Rarity,
    RemainingTHC,
    UpgradeStatus,
    calculateHarvestBonus,
    calculateMaxPlants,
    calculateMaxSeeds,
    calculateWateringTime,
    indexToRarity
} from '@growerz/shared';
import { DEFAULT_WATERTIME } from "@growerz/shared/common/constants";

import { useApi } from '../contexts/ApiContext';
import { useNotification } from '../contexts/NotificationContext';
import { useSolanaWallet } from './useSolanaWallet';

// Define the context
interface GrowhouseContextProps {
    actionsDisabled: {
        waterAll: boolean,
        harvestAll: boolean,
        pruneAll: boolean
    },
    loadingGrowhouse: boolean,
    growhouse: GrowhouseData | undefined,
    seeds: PlantData[],
    plants: PlantData[],
    growerz: GrowerData[],
    rarity: Rarity,
    stats: GrowhouseStats | undefined,
    remainingTHC: RemainingTHC | undefined,
    harvestCaps: HarvestCaps,
    checkPlantsStatus: () => void,
    setLoadingGrowhouse: (loading: boolean) => void,
    refreshData: (growhouseId?: number) => void,
    updateHarvestCaps: (harvestCaps: HarvestCaps) => void
    updateRemainingTHC: (remainingTHC: RemainingTHC) => void
}

const GrowhouseContext = createContext<GrowhouseContextProps>({
    actionsDisabled: {
        waterAll: false,
        harvestAll: false,
        pruneAll: false
    },
    loadingGrowhouse: false,
    growhouse: undefined,
    seeds: [],
    plants: [],
    growerz: [],
    rarity: Rarity.COMMON,
    stats: undefined,
    remainingTHC: undefined,
    harvestCaps: {
        common: {
            plants: false,
            seeds: false
        },
        uncommon: {
            plants: false,
            seeds: false
        },
        rare: {
            plants: false,
            seeds: false
        },
        epic: {
            plants: false,
            seeds: false
        },
        mythic: {
            plants: false,
            seeds: false
        }
    },
    checkPlantsStatus: () => { },
    setLoadingGrowhouse: () => { },
    refreshData: () => { },
    updateHarvestCaps: () => { },
    updateRemainingTHC: () => { }
});

// Define the custom hook
export const useGrowhouse = () => {
    const context = useContext(GrowhouseContext);
    if (!context) {
        throw new Error('useGrowhouse must be used within a GrowhouseProvider');
    }
    return context;
};

// Define the provider component
export const GrowhouseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const { get } = useApi();
    const { publicKey } = useWallet();
    const { updateBalance } = useSolanaWallet();
    const { addErrorNotification } = useNotification();

    const [loadingGrowhouse, setLoadingGrowhouse] = useState<boolean>(false);
    const [growhouse, setGrowhouse] = useState<GrowhouseData>();
    const [growhouseId, setGrowhouseId] = useState<number>();
    const [seeds, setSeeds] = useState<PlantData[]>([]);
    const [plants, setPlants] = useState<PlantData[]>([]);
    const [growerz, setGrowerz] = useState<GrowerData[]>([]);
    const [rarity, setRarity] = useState<Rarity>(Rarity.COMMON);
    const [stats, setStats] = useState<GrowhouseStats>();
    const [upgradeStatus, setUpgradeStatus] = useState<UpgradeStatus>();
    const [remainingTHC, setRemainingTHC] = useState<RemainingTHC | undefined>();
    const [harvestCaps, setHarvestCaps] = useState<HarvestCaps>({
        common: {
            plants: false,
            seeds: false
        },
        uncommon: {
            plants: false,
            seeds: false
        },
        rare: {
            plants: false,
            seeds: false
        },
        epic: {
            plants: false,
            seeds: false
        },
        mythic: {
            plants: false,
            seeds: false
        }
    });

    const [actionsDisabled, setActionsDisabled] = useState<{
        waterAll: boolean,
        harvestAll: boolean,
        pruneAll: boolean
    }>({
        waterAll: false,
        harvestAll: false,
        pruneAll: false
    });

    const updateHarvestCaps = useCallback((harvestCaps: HarvestCaps) => {
        setHarvestCaps(harvestCaps);
    }, []);

    const updateRemainingTHC = useCallback((remainingTHC: RemainingTHC) => {
        setRemainingTHC(remainingTHC);
    }, []);

    const updatePlantStatus = useCallback((newPlants: PlantData[], thcRemaining: RemainingTHC, status: UpgradeStatus) => {
        const canHarvest = async (plant: PlantData) => {
            let plantRarity = plant.strain.rarity.name;
            let canHarvest = true;

            // Can upgrade should override unless payout received is higher than upgrade cost, then it will return false all the time
            if (thcRemaining) {
                if (thcRemaining.payoutReceived > thcRemaining.upgradeCost)
                    return false;
                else
                    return true;
            }

            // For each rarity, it should count harvested is less than required which means it can be harvested, but we also need to count
            // the plants already being harvested by rarity and add it to the harvested count so it's not above the required, then return false

            switch (plantRarity) {
                case Rarity.COMMON:
                    canHarvest = status.plants_harvested.common < status.plants_required.common;
                    break;
                case Rarity.UNCOMMON:
                    canHarvest = status.plants_harvested.uncommon < status.plants_required.uncommon;
                    break;
                case Rarity.RARE:
                    canHarvest = status.plants_harvested.rare < status.plants_required.rare;
                    break;
                case Rarity.EPIC:
                    canHarvest = status.plants_harvested.epic < status.plants_required.epic;
                    break;
                case Rarity.MYTHIC:
                    canHarvest = status.plants_harvested.mythic < status.plants_required.mythic;
                    break;
                default:
                    throw new Error("Invalid plant rarity");
            }

            return canHarvest;
        }

        setActionsDisabled({
            waterAll: newPlants.filter((plant: any) => new Date(plant.next_watering_time!) < new Date() && (plant.state === PlantState.SAPLING || plant.state === PlantState.PLANT) && canHarvest(plant)).length === 0,
            harvestAll: newPlants.filter((plant: any) => new Date(plant.next_watering_time!) < new Date() && (plant.state === PlantState.HARVESTABLE) && canHarvest(plant)).length === 0,
            pruneAll: newPlants.filter((plant: any) => plant.state === PlantState.DEAD && canHarvest(plant)).length === 0
        })
    }, []);

    const checkPlantsStatus = useCallback(() => {
        if (!plants || !remainingTHC || !upgradeStatus) return;
        updatePlantStatus(plants, remainingTHC, upgradeStatus);
    }, [plants, remainingTHC, upgradeStatus, updatePlantStatus]);

    const refreshData = useCallback((newId?: number) => {
        if (!publicKey) return;

        if (!newId) newId = growhouseId;

        setLoadingGrowhouse(true);
        get(`/growhouse/${newId}`).then((response) => {
            if (!response.success || response.data.growhouse.account.address !== publicKey.toBase58()) {
                navigate('/');
                return;
            }

            setGrowhouseId(response.data.growhouse.id);
            setGrowhouse(response.data.growhouse);
            setGrowerz(response.data.growhouse.growers);
            setSeeds(response.data.seeds);
            setPlants(response.data.plants);
            setRarity(calculateGrowhouseRarity(response.data.growhouse.growers));
            setRemainingTHC(response.data.remainingTHC);
            setUpgradeStatus(response.data.growhouse.upgrade_status);

            let maxGrowers = response.data.growhouse.base.maxGrowers;
            let maxPlants = calculateMaxPlants(response.data.growhouse);
            let maxSeeds = calculateMaxSeeds(response.data.growhouse);
            let wateringTime = String(DEFAULT_WATERTIME - calculateWateringTime(response.data.growhouse));
            let harvestBonus = String(calculateHarvestBonus(response.data.growhouse));

            setStats({
                maxGrowers,
                maxPlants,
                maxSeeds,
                wateringTime,
                harvestBonus
            });

            updateBalance();
            updatePlantStatus(response.data.growhouse.plants, response.data.remainingTHC, response.data.growhouse.upgrade_status);
        }).catch((error: any) => {
            if (error.message === 'Outdated client') {
                addErrorNotification("Failed to get growhouse data", `${error.message}, refresh to update`);
            } else {
                addErrorNotification("Failed to get growhouse data", "Contact support!");
            }
            console.log("Failed to get growhouse data: ", error.message);
        }).finally(() => {
            setLoadingGrowhouse(false);
        });
    }, [growhouseId, publicKey, addErrorNotification, navigate, get, updateBalance, updatePlantStatus]);

    const calculateGrowhouseRarity = (growers: any) => {
        if (!growers || growers.length === 0) return Rarity.COMMON;

        let total = 0;
        growers.forEach((grower: any) => total += grower.class.id);
        let rarity = Math.round(total / growers.length);

        return indexToRarity(rarity);
    }

    const value = useMemo(() => ({
        actionsDisabled,
        loadingGrowhouse,
        growhouse,
        seeds,
        plants,
        growerz,
        rarity,
        stats,
        remainingTHC,
        harvestCaps,
        checkPlantsStatus,
        setLoadingGrowhouse,
        refreshData,
        updateHarvestCaps,
        updatePlantStatus,
        updateRemainingTHC
    }), [
        actionsDisabled,
        loadingGrowhouse,
        growhouse,
        seeds,
        plants,
        growerz,
        rarity,
        stats,
        remainingTHC,
        harvestCaps,
        checkPlantsStatus,
        setLoadingGrowhouse,
        refreshData,
        updateHarvestCaps,
        updatePlantStatus,
        updateRemainingTHC
    ]);

    return (
        <GrowhouseContext.Provider value={value}>
            {children}
        </GrowhouseContext.Provider>
    );
}

export default useGrowhouse;
