import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { GrowerData, GrowhouseData, NotificationType, PlantAction, PlantData, PlantState, calculateMaxPlants, calculateMaxSeeds } from "@growerz/shared";

import { useApi } from "../../../contexts/ApiContext";
import { useNotification } from "../../../contexts/NotificationContext";
import useGrowhouse from "../../../hooks/useGrowhouse";

const SeedTransferModal = ({
    seed
}: {
    seed: PlantData
}) => {
    const { get, post } = useApi();
    const { addNotification, addErrorNotification } = useNotification();
    const { refreshData } = useGrowhouse();
    const [loading, setLoading] = useState(true);
    const [showing, setShowing] = useState(false);
    const [growhouses, setGrowhouses] = useState<GrowhouseData[]>();

    function show() {
        setShowing(true);
        get('/account/growhouses').then((response) => {
            if (!response.success) {
                addErrorNotification("Action failed!", `Unable to get growhouses, ${response.message}`);
                console.error("Failed to get growhouses: " + response.message);
            } else {
                let data: GrowhouseData[] = response.data;
                setGrowhouses(data.filter(x => x.id !== seed.growhouse.id));
            }
            setLoading(false);
        }).catch((error) => {
            addErrorNotification("Action failed!", 'Unable to get growhouses, contact support!');
            console.error("Failed to get growhouses: " + error.message);
            setLoading(false);
        });
    }

    function hide() {
        setShowing(false);
    }

    useEffect(() => {
        setLoading(false);
    }, [growhouses]);

    function handleTransfer(event: any) {
        let growhouseId = event.currentTarget.value;

        post(`/plant/${seed.id}/${PlantAction.TRANSFER}`, {
            growhouseId
        }).then((response) => {
            if (!response.success) {
                addErrorNotification("Action failed!", `Unable to transfer seed, ${response.message}`);
                console.error("Failed to transfer seed: " + response.message);
            } else {
                addNotification("Seed Transferred", "Seed has been successfully transferred", NotificationType.Success);
                hide();
                refreshData();
            }
            setLoading(false);
        }).catch((error) => {
            addErrorNotification("Action failed!", 'Unable to transfer seed, contact support!');
            console.error("Failed to transfer seed: " + error.message);
            setLoading(false);
        })

        refreshData();
    }

    const Growhouses = () => {
        if (!growhouses || loading) return <>Loading Growhouses...</>
        if (growhouses && growhouses.length === 0) return <>No Growhouses available</>

        return <Row className="g-3 mt-3">
            {growhouses.filter(x => x.id !== seed.growhouse.id).map((growhouse) => {
                const GrowerImage = ({ grower }: { grower: GrowerData }) => {
                    return <img
                        alt={`Grower #${grower.id}`}
                        src={grower.image}
                        className="d-inline-block"
                        style={{ height: '100%', width: '100%', maxHeight: 100, maxWidth: 100, backgroundColor: '#000' }} />
                }

                return <Col xs={12} md={4} key={growhouse.id}>
                    <Card className="bg-black text-light">
                        <Card.Header className="bg-dark text-center">{growhouse.nickname ?? `Growhouse #${growhouse.id}`}</Card.Header>
                        <Card.Body className="text-light px-2 pb-2">
                            {growhouse.growers && growhouse.growers.length > 0 && (<>
                                <Row className="g-2 justify-content-center text-center">
                                    {growhouse.growers.map((grower) => {
                                        return <Col key={grower.id}>
                                            <GrowerImage grower={grower} />
                                        </Col>
                                    })}
                                </Row>
                                <hr />
                            </>)}
                            <Row>
                                <Col className="fs-7">
                                    <Row className="justify-content-between">
                                        <Col xs={8}>ID:</Col>
                                        <Col className="text-end">{growhouse.id}</Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                        <Col xs={8}>Level:</Col>
                                        <Col className="text-end">{growhouse.level}</Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                        <Col xs={8}>Growerz:</Col>
                                        <Col className="text-end">{growhouse.growers.length}/{growhouse.base?.maxGrowers}</Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                        <Col xs={8}>Plants:</Col>
                                        <Col className="text-end">{growhouse.plants?.length}/{calculateMaxPlants(growhouse)}</Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                        <Col xs={8}>Seeds:</Col>
                                        <Col className="text-end">{growhouse.seeds?.length}/{calculateMaxSeeds(growhouse)}</Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                        <Col xs={8}>Waterable:</Col>
                                        <Col className="text-end">{growhouse.plants?.filter(x => new Date(x.next_watering_time!) < new Date() && x.state !== PlantState.HARVESTABLE).length}</Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                        <Col xs={8}>Harvestable:</Col>
                                        <Col className="text-end">{growhouse.plants?.filter(x => x.state === PlantState.HARVESTABLE).length}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="p-0">
                            <div className='w-100 br-0 br-b-7'>
                                <Button variant="thc" className='w-100 br-0 br-b-7 fs-6 text-light' size="lg" onClick={handleTransfer} value={growhouse.id}>
                                    Transfer
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            })}
        </Row>
    }

    return <>
        <Modal variant="dark" show={showing} onHide={hide} className="dark-modal" size={"lg"}>
            <Modal.Header closeButton>
                Transfer Seed
            </Modal.Header>
            <Modal.Body>
                <h6>What growhouse would you like to move this seed to?</h6>
                <Growhouses />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="text-white mt-3 px-3" onClick={hide}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Button variant={"thc"} size="sm" className="text-white w-100" onClick={show}>
            Transfer
        </Button>
    </>
}

export default SeedTransferModal;