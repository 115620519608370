import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { DispensarySeedData, PaymentMethod } from "@growerz/shared";

import { SeedImages } from "../../common/SeedImages";
import useCart from "../../../hooks/useCart";
import { useSolanaWallet } from "../../../hooks/useSolanaWallet";

const thcToken = require('../../../assets/images/token.png');
const solToken = require('../../../assets/images/solana.png');

const DispensarySeed = ({
    seed,
    maxSeeds
}: {
    seed: DispensarySeedData,
    maxSeeds: boolean
}) => {
    const { balance, tokenBalance } = useSolanaWallet();
    const { paymentMethod, total, getCount, addToCart, removeFromCart } = useCart();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleAddSeed = (event: any) => {
        if (paymentMethod === PaymentMethod.SOL) {
            if (balance < seed.sol) {
                return;
            }
        }
        addToCart(seed);
    };

    const handleRemoveSeed = (event: any) => {
        removeFromCart(seed);
    };

    const ActionButton = () => {
        let itemsInCart = getCount(seed.id);
        let canAddMore = (paymentMethod === PaymentMethod.THC && tokenBalance >= (total + seed.cost)) || (paymentMethod === PaymentMethod.SOL && balance >= (total + seed.sol));

        return <Row className="g-1">
            <Col>
                <Button size={"sm"} className="text-light" variant={"thc"} onClick={handleRemoveSeed} value={seed.id} disabled={itemsInCart <= 0 || loading}>
                    &minus;
                </Button>
            </Col>
            <Col>
                {itemsInCart}
            </Col>
            <Col>
                <Button size={"sm"} className="text-light" variant={"thc"} onClick={handleAddSeed} value={seed.id} disabled={itemsInCart >= 10 || maxSeeds || loading || !canAddMore}>
                    &#43;
                </Button>
            </Col>
        </Row>
    }

    return <>
        <tr className="d-none d-md-table-row">
            <td className="py-2"><img alt={seed.rarity + " Seed"} src={SeedImages[seed.rarity]} height={26} /></td>
            <td className={`text-start rarity-text-${seed.rarity.toLowerCase()}`}>{seed.rarity}</td>
            <td className="fs-7">{seed.strains.map(strain => strain.name).join(', ')}</td>
            <td>{paymentMethod === PaymentMethod.THC ? seed.cost : seed.sol} <img src={paymentMethod === PaymentMethod.THC ? thcToken : solToken} height={28} alt="Token Icon" /></td>
            <td><ActionButton /></td>
        </tr>
        <tr className="d-table-row d-md-none">
            <td><img alt={seed.rarity + " Seed"} src={SeedImages[seed.rarity]} height={24} /></td>
            <td className={`fs-6 text-start rarity-text-${seed.rarity.toLowerCase()}`}>{seed.rarity}</td>
            <td className="fs-6">{paymentMethod === PaymentMethod.THC ? seed.cost : seed.sol}&nbsp;<img src={paymentMethod === PaymentMethod.THC ? thcToken : solToken} height={18} alt="Token Icon" /></td>
            <td><ActionButton /></td>
        </tr>
    </>
}

export default DispensarySeed;