import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { PublicKey } from "@solana/web3.js";
import { formatCurrency } from "@growerz/shared";
import { Row, Col } from "react-bootstrap";
import { useSolanaWallet } from "../hooks/useSolanaWallet";

const getProvider = () => {
    if ('phantom' in window) {
        const provider = (window.phantom as any).solana;

        if (provider?.isPhantom) {
            return provider;
        }
    }

    if ('solflare' in window) {
        const provider = (window.solflare as any);

        if (provider?.isSolflare) {
            return provider;
        }
    }
}

const WalletButton = () => {
    const { connected } = useWallet();
    const { tokenBalance } = useSolanaWallet();

    const token = {
        publicKey: new PublicKey(process.env.REACT_APP_SPL_TOKEN ?? ''),
        address: process.env.REACT_APP_SPL_TOKEN,
        image: require('../assets/images/token.png')
    }

    if (!getProvider()) {
        return <Row className="text-center">
            <Col>
                <button className="wallet-adapter-button wallet-adapter-button-trigger get-phantom"
                    tabIndex={0}
                    type="button"
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => window.open('https://phantom.app/', '_blank')}>
                    Get Phantom
                </button>
            </Col>
        </Row>
    }

    return (
        <Row className="text-center">
            {connected &&
                <Col className="pt-2 text-end">
                    <span className="fs-6 me-2">{formatCurrency(tokenBalance)}</span>
                    <img src={token.image} height={30} alt="THC Token" />
                </Col>
            }
            <Col>
                <WalletMultiButton />
            </Col>
        </Row>
    );
}

export default WalletButton;