import { useCallback, useEffect, useState } from 'react';
import { ProgressBar, Row, Col, ButtonGroup, OverlayTrigger, ToggleButton, Tooltip } from 'react-bootstrap';
import { GrowhouseUpgradeStatus, HarvestCaps, PaymentMethod, roundDecimal } from '@growerz/shared';

import { useSolanaWallet } from '../../../hooks/useSolanaWallet';
import GrowhouseUpgradeModal from './GrowhouseUpgradeModal';
import useGrowhouse from '../../../hooks/useGrowhouse';

const tokenImg = require('../../../assets/images/token.png')
const solanaImg = require('../../../assets/images/solana.png');
const tokenImgBw = require('../../../assets/images/token-bw.png')
const solanaImgBw = require('../../../assets/images/solana-bw.png');

const UpgradeStatus = () => {
    const { balance, tokenBalance } = useSolanaWallet();
    const { growhouse, plants, harvestCaps, stats, remainingTHC, updateHarvestCaps } = useGrowhouse();

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.THC);
    const [progressPercentage, setProgressPercentage] = useState<number>(0);
    const [upgradeStatus, setUpgradeStatus] = useState<GrowhouseUpgradeStatus>({
        totalPlantsHarvested: 0,
        totalPlantsRequired: 0,
        plantsHarvested: {
            common: 0,
            uncommon: 0,
            rare: 0,
            epic: 0,
            mythic: 0
        },
        plantsRequired: {
            common: 0,
            uncommon: 0,
            rare: 0,
            epic: 0,
            mythic: 0
        },
        plantsRemaining: {
            common: 0,
            uncommon: 0,
            rare: 0,
            epic: 0,
            mythic: 0
        },
        totalPercentage: 0,
        thcPercentage: 0,
        thcRequired: 0,
        solPercentage: 0,
        solRequired: 0,
        commonPercentage: 0,
        uncommonPercentage: 0,
        rarePercentage: 0,
        epicPercentage: 0,
        mythicPercentage: 0,
        harvestPercentage: 0
    });

    const CustomProgressBar = ({
        variant,
        remaining,
        required,
        percentage
    }: {
        variant: string,
        remaining: number,
        required: number,
        percentage: number
    }) => {
        if (required > 0) {
            if (required === remaining) {
                return <ProgressBar variant={'dark'} className="text-white" now={100} label={remaining + ' Remaining'} />
            } else if (percentage === 100) {
                return <ProgressBar variant={variant} now={percentage} label={'Completed'} striped animated />
            } else {
                return <ProgressBar variant={variant} now={percentage} label={remaining + ' Remaining'} striped={remaining <= 0} animated={remaining <= 0} />
            }
        } else {
            return <ProgressBar variant={variant} now={percentage} label={'Exempt'} striped animated />
        }
    }

    const toggleUpgradePaymentMethod = () => {
        if (paymentMethod === PaymentMethod.THC)
            setPaymentMethod(PaymentMethod.SOL);
        else if (paymentMethod === PaymentMethod.SOL)
            setPaymentMethod(PaymentMethod.THC);
    }

    const checkHarvestCaps = useCallback((status: GrowhouseUpgradeStatus) => {
        if (!stats || !harvestCaps) return;

        const rarities: (keyof HarvestCaps)[] = ['common', 'uncommon', 'rare', 'epic', 'mythic'];

        if (status.harvestPercentage >= 100) {
            if (remainingTHC && remainingTHC.payoutReceived > remainingTHC.upgradeCost) {
                rarities.forEach(rarity => {
                    harvestCaps[rarity].plants = true;
                    harvestCaps[rarity].seeds = true;
                });
            }
        } else {
            rarities.forEach(rarity => {
                const plantsHarvested = status.plantsHarvested[rarity];
                const plantsRequired = status.plantsRequired[rarity];
                const seedsCount = plants.filter(x => x.strain.rarity.name.toLowerCase() === rarity).length;

                if (plantsHarvested >= plantsRequired || plantsRequired === 0) {
                    harvestCaps[rarity].plants = true;
                }
                if (plantsHarvested + seedsCount >= plantsRequired) {
                    harvestCaps[rarity].seeds = true;
                }
            });
        }

        if (plants.length >= stats.maxPlants) {
            rarities.forEach(rarity => {
                harvestCaps[rarity].seeds = true;
            });
        }

        updateHarvestCaps(harvestCaps);
    }, [remainingTHC, harvestCaps, plants, stats, updateHarvestCaps]);

    useEffect(() => {
        if (!growhouse || !growhouse.upgrade_status) return;

        let thcPercentage = Math.min((tokenBalance / growhouse.upgrade_status.thc_required) * 100, 100);
        let solPercentage = Math.min((balance / growhouse.upgrade_status.sol_required) * 100, 100);
        let costPercentage = (paymentMethod === PaymentMethod.THC ? thcPercentage : solPercentage);

        const plantsHarvested = growhouse.upgrade_status.plants_harvested;
        const plantsRequired = growhouse.upgrade_status.plants_required;

        let checkedRarities = 0;

        if (plantsRequired.common > 0) checkedRarities++;
        if (plantsRequired.uncommon > 0) checkedRarities++;
        if (plantsRequired.rare > 0) checkedRarities++;
        if (plantsRequired.epic > 0) checkedRarities++;
        if (plantsRequired.mythic > 0) checkedRarities++;

        let commonPercentage = plantsRequired.common > 0 ? Math.min((plantsHarvested.common / plantsRequired.common) * 100, 100) : 0;
        let uncommonPercentage = plantsRequired.uncommon > 0 ? Math.min((plantsHarvested.uncommon / plantsRequired.uncommon) * 100, 100) : 0;
        let rarePercentage = plantsRequired.rare > 0 ? Math.min((plantsHarvested.rare / plantsRequired.rare) * 100, 100) : 0;
        let epicPercentage = plantsRequired.epic > 0 ? Math.min((plantsHarvested.epic / plantsRequired.epic) * 100, 100) : 0;
        let mythicPercentage = plantsRequired.mythic > 0 ? Math.min((plantsHarvested.mythic / plantsRequired.mythic) * 100, 100) : 0;

        let totalRarities = checkedRarities * 100;
        let totalRarityPercentage = commonPercentage + uncommonPercentage + rarePercentage + epicPercentage + mythicPercentage;
        let harvestPercentage = Math.min((totalRarityPercentage / totalRarities) * 100, 100);
        let totalPercentage = (costPercentage + harvestPercentage) / 2;

        let totalPlantsHarvested = plantsHarvested.common + plantsHarvested.uncommon + plantsHarvested.rare + plantsHarvested.epic + plantsHarvested.mythic;
        let totalPlantsRequired = plantsRequired.common + plantsRequired.uncommon + plantsRequired.rare + plantsRequired.epic + plantsRequired.mythic;

        let plantsRemaining = {
            common: Math.max(0, plantsRequired.common - plantsHarvested.common),
            uncommon: Math.max(plantsRequired.uncommon - plantsHarvested.uncommon),
            rare: Math.max(plantsRequired.rare - plantsHarvested.rare),
            epic: Math.max(plantsRequired.epic - plantsHarvested.epic),
            mythic: Math.max(plantsRequired.mythic - plantsHarvested.mythic),
        }

        let status = {
            totalPlantsHarvested,
            totalPlantsRequired,
            plantsHarvested,
            plantsRequired,
            plantsRemaining,
            totalPercentage,
            thcPercentage,
            thcRequired: growhouse.upgrade_status.thc_required,
            solPercentage,
            solRequired: growhouse.upgrade_status.sol_required,
            commonPercentage,
            uncommonPercentage,
            rarePercentage,
            epicPercentage,
            mythicPercentage,
            harvestPercentage
        }

        setUpgradeStatus(status);
        setProgressPercentage(growhouse.max_level ? 100 : status.totalPercentage);
        checkHarvestCaps(status);
    }, [balance, growhouse, tokenBalance, paymentMethod, remainingTHC, checkHarvestCaps]);

    if (!growhouse) return <></>;

    return <>
        <Row className="justify-content-between">
            <Col>
                <h4>Level: {growhouse.max_level ? `Max ${growhouse.level}` : growhouse.level}</h4>
            </Col>
            <Col className={`text-end pt-0 pb-2 ${growhouse.max_level || growhouse.level === 10 ? 'd-none' : ''}`}>
                <OverlayTrigger trigger={["focus", "hover"]} placement={'bottom'} overlay={<Tooltip>Payment Method (THC/SOL)</Tooltip>}>
                    <ButtonGroup className="switch-wrap" onChange={toggleUpgradePaymentMethod}>
                        <ToggleButton
                            className="switch"
                            key={101}
                            id={`radio-${101}`}
                            type="radio"
                            variant={'link'}
                            name="payment-value"
                            value={"thc"}
                            checked={paymentMethod === PaymentMethod.THC}
                            onClick={toggleUpgradePaymentMethod}
                        >
                            <img src={paymentMethod === PaymentMethod.THC ? tokenImg : tokenImgBw} height={24} alt="THC Token" />
                        </ToggleButton>
                        <ToggleButton
                            className="switch"
                            key={102}
                            id={`radio-${102}`}
                            type="radio"
                            variant={'link'}
                            name="payment-value"
                            value={"sol"}
                            checked={paymentMethod === PaymentMethod.SOL}
                            onClick={toggleUpgradePaymentMethod}
                        >
                            <img src={paymentMethod === PaymentMethod.SOL ? solanaImg : solanaImgBw} height={24} alt="Solana" />
                        </ToggleButton>
                    </ButtonGroup>
                </OverlayTrigger>
            </Col>
        </Row>
        <ProgressBar variant={"thc"} now={progressPercentage} striped label={roundDecimal(progressPercentage, 2) + '%'} animated={growhouse.max_level} />
        {growhouse.max_level && (
            <Row className="fs-7 mt-2">
                <Col>
                    <i className="text-success">Growhouse is fully upgraded!</i>
                </Col>
            </Row>
        )}

        {!growhouse.max_level && (
            <>
                <Row className={`fs-7 mt-2 ${paymentMethod === PaymentMethod.THC ? '' : 'd-none'}`}>
                    <Col xs={5}>
                        THC Required:
                    </Col>
                    <Col className="text-end">
                        {Math.floor(tokenBalance)} / {upgradeStatus.thcRequired} ({roundDecimal(upgradeStatus.thcPercentage, 2)}%)
                    </Col>
                </Row>
                <Row className={`fs-7 mt-2 ${paymentMethod === PaymentMethod.SOL ? '' : 'd-none'}`}>
                    <Col xs={5}>
                        SOL Required:
                    </Col>
                    <Col className="text-end">
                        {balance} / {upgradeStatus.solRequired} ({roundDecimal(upgradeStatus.solPercentage, 2)}%)
                    </Col>
                </Row>
                <Row className="fs-7 mt-2">
                    <Col xs={7}>
                        Total Required Harvested:
                    </Col>
                    <Col className="text-end">
                        {roundDecimal(upgradeStatus.harvestPercentage, 0)}%
                    </Col>
                </Row>
                {(!remainingTHC) && (
                    <>
                        <hr />
                        <h5>Upgrade Status</h5>
                        <hr className="mt-0 mb-2" />
                        <Row className="">
                            <Col xs={4} md={5} xxl={3} className="fs-7">
                                Common:
                            </Col>
                            <Col>
                                <CustomProgressBar
                                    variant="common"
                                    percentage={upgradeStatus.commonPercentage === 0 && upgradeStatus.plantsRequired.common === 0 ? 100 : upgradeStatus.commonPercentage}
                                    remaining={upgradeStatus.plantsRemaining.common}
                                    required={upgradeStatus.plantsRequired.common} />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={4} md={5} xxl={3} className="fs-7">
                                Uncommon:
                            </Col>
                            <Col>
                                <CustomProgressBar
                                    variant="uncommon"
                                    percentage={upgradeStatus.uncommonPercentage === 0 ? 100 : upgradeStatus.uncommonPercentage}
                                    remaining={upgradeStatus.plantsRemaining.uncommon}
                                    required={upgradeStatus.plantsRequired.uncommon} />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={4} md={5} xxl={3} className="fs-7">
                                Rare:
                            </Col>
                            <Col>
                                <CustomProgressBar
                                    variant="rare"
                                    percentage={upgradeStatus.rarePercentage === 0 ? 100 : upgradeStatus.rarePercentage}
                                    remaining={upgradeStatus.plantsRemaining.rare}
                                    required={upgradeStatus.plantsRequired.rare} />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={4} md={5} xxl={3} className="fs-7">
                                Epic:
                            </Col>
                            <Col>
                                <CustomProgressBar
                                    variant="epic"
                                    percentage={upgradeStatus.epicPercentage === 0 ? 100 : upgradeStatus.epicPercentage}
                                    remaining={upgradeStatus.plantsRemaining.epic}
                                    required={upgradeStatus.plantsRequired.epic} />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={4} md={5} xxl={3} className="fs-7">
                                Mythic:
                            </Col>
                            <Col>
                                <CustomProgressBar
                                    variant="mythic"
                                    percentage={upgradeStatus.mythicPercentage === 0 ? 100 : upgradeStatus.mythicPercentage}
                                    remaining={upgradeStatus.plantsRemaining.mythic}
                                    required={upgradeStatus.plantsRequired.mythic} />
                            </Col>
                        </Row>
                    </>
                )}
                {remainingTHC && (
                    <Row className="fs-6 py-2">
                        <Col>
                            Harvest Limit:
                        </Col>
                        <Col className="text-end">
                            <span className={remainingTHC.payoutReceived < remainingTHC.upgradeCost ? 'text-success' : 'text-danger'}>
                                {remainingTHC.payoutReceived}
                            </span> / {remainingTHC.upgradeCost}
                        </Col>
                    </Row>
                )}
                <Row className="pt-1">
                    <Col>
                        <GrowhouseUpgradeModal paymentMethod={paymentMethod} upgradeable={upgradeStatus.totalPercentage >= 100} />
                    </Col>
                </Row>
            </>
        )}
    </>
}

export default UpgradeStatus;